<template>
  <i
    v-bind:class="[
      value
        ? 'fas fa-check-circle text-success'
        : 'fas fa-times-circle text-danger',
    ]"
    v-tooltip="tooltipOptions"
  ></i>
</template>
<script>
export default {
  name: "TableBooleanDisabled",
  props: {
    value: {
      default: 1,
    },
  },
  computed: {
    tooltipOptions() {
      return {
        content: this.value ? "Aktif" : "Pasif",
        placement: "bottom",
      };
    },
  },
};
</script>